import React from "react";
import {Helmet} from "react-helmet";
import {Layout} from "@components";
import {TopSection} from "@templates/wallet/TopSection";
import {WhyWalletSection} from "@templates/wallet/WhyWalletSection";
import styled from "styled-components";
import {LAPTOP_MOBILE_POINTER, MOBILE_MINIMOBILE_POINTER} from "@tools/constants";
import {WhatWalletSection} from "@templates/wallet/WhatWalletSection";
import {WalletPossibilitiesSection} from "@templates/wallet/WalletPossibilitiesSection";
import {GetStartSection} from "@templates/wallet/GetStartSection";
import {CardSoonSection} from "@templates/wallet/CardSoonSection";
import {QuizSection} from "@templates/main";
import {AnalyzeWalletSection} from "@templates/wallet/AnalyzeWalletSection";
import {WalletStickyStepsSection} from "@templates/wallet/StickyStepsSection";


const WalletPage = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>
                    Кошелек для самозанятых РФ электронный | Открыть кошелек самозанятого
                </title>
                <meta
                    name="description"
                    content="В поиске кошелька для самозанятых РФ? Удобный сервис для самозанятых граждан. Автоматизация налогов, чеков, бухгалтерии. Моментальный вывод денег на карту."
                />
                <link
                    rel="canonical"
                    href="https://xn--80aapgyievp4gwb.xn--p1ai/wallet"
                />
            </Helmet>
            <Layout disableWidth={true}>

                <MaxWidthBox>
                    <TopSection/>
                </MaxWidthBox>

                <WhyWalletSection/>

                <MaxWidthBox>
                    <WhatWalletSection/>
                    <WalletPossibilitiesSection/>
                    <WalletStickyStepsSection/>
                    <AnalyzeWalletSection/>
                    <GetStartSection/>
                    <CardSoonSection/>
                </MaxWidthBox>
                <QuizSection/>

            </Layout>
        </>
    );
};

export default WalletPage;

const MaxWidthBox = styled.div`

    margin: 0 auto;

    @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
        max-width: 1204px;
        padding: 0 32px;
    }
    @media (min-width: ${MOBILE_MINIMOBILE_POINTER + 1}px) and (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        max-width: 609px;
        padding: 0 20px;
    }
    @media (max-width: ${MOBILE_MINIMOBILE_POINTER}px) {
        max-width: 375px;
        padding: 0 16px;
    }

`;
