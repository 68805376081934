import styled from "styled-components";
import React from "react";
import {StaticImage} from "gatsby-plugin-image";
import {ButtonUI, TypographyUI} from "@ui";
import {LAPTOP_MOBILE_POINTER} from "@tools/constants";
import {useWindowSize} from "@tools/hooks";
import {AdaptiveLinkCt} from "@components";

export const WalletPossibilitiesSection = () => {

    const { width } = useWindowSize();

    return (
        <WalletPossibilitiesSectionContainer>
            <WalletPossibilitiesSectionTitleContainer>
                <WalletPossibilitiesSectionTitle swichPoint={LAPTOP_MOBILE_POINTER}>Один сервис — много возможностей</WalletPossibilitiesSectionTitle>
            </WalletPossibilitiesSectionTitleContainer>

            <WalletPossibilitiesSectionItems>

                <WalletPossibilitiesSectionItem style={{'width': '100%', 'height': width && width > LAPTOP_MOBILE_POINTER ? '438px' : '100%', 'background': 'var(--background-second)'}}>
                    <WalletPossibilitiesSectionItemInfo style={{'width': width && width > LAPTOP_MOBILE_POINTER ? '470px' : '100%'}}>
                        <TypographyUI.H3 className="title">Прием платежей по ссылке или QR-коду</TypographyUI.H3>
                        <TypographyUI.Body1Regular className="description">Используйте одну и ту же ссылку в нескольких каналах продаж: на сайте, в соцсетях или мессенджере. Если работаете офлайн — распечатайте QR-код для оплаты</TypographyUI.Body1Regular>


                    </WalletPossibilitiesSectionItemInfo>
                    <WalletPossibilitiesSectionItemImage style={{'position': width && width > LAPTOP_MOBILE_POINTER ? 'absolute' : 'relative', 'top': width && width > LAPTOP_MOBILE_POINTER ? 'calc(50% - 170px)' : '15px', 'height': width && width > LAPTOP_MOBILE_POINTER ? '339px' : '100%', 'right': width && width > LAPTOP_MOBILE_POINTER ? '100px' : '0', 'width': width && width > LAPTOP_MOBILE_POINTER ? '446px' : '100%'}}>
                        <StaticImage
                            src="../../../images/wallet/WalletPossibilitiesSectionImage1.png"
                            loading="eager"
                            placeholder="none"
                            formats={["auto", "webp"]}
                            layout="constrained"
                            objectFit="contain"
                            alt=""
                        />
                    </WalletPossibilitiesSectionItemImage>
                </WalletPossibilitiesSectionItem>


                <WalletPossibilitiesSectionItem style={{'width': width && width > LAPTOP_MOBILE_POINTER ? '555px' : '100%', 'height': width && width > LAPTOP_MOBILE_POINTER ? '438px' : '100%', 'background': 'var(--background-main-grey)'}}>
                    <WalletPossibilitiesSectionItemInfo>
                        <TypographyUI.H3 className="title">Автоформирование чеков</TypographyUI.H3>
                        <TypographyUI.Body1Regular className="description">Сервис интегрирован с ФНС, поэтому вам не нужно думать о чеках: Кошелек сам сформирует документ, отправит клиенту и передаст данные в «Мой налог»</TypographyUI.Body1Regular>
                    </WalletPossibilitiesSectionItemInfo>
                    <WalletPossibilitiesSectionItemImage style={{'position': width && width > LAPTOP_MOBILE_POINTER ? 'absolute' : 'relative', 'bottom': '20px', 'right': width && width > LAPTOP_MOBILE_POINTER ? 'calc(50% - 175px)' : '0', 'width': width && width > LAPTOP_MOBILE_POINTER ? '351px' : '100%'}}>
                        <StaticImage
                            src="../../../images/wallet/WalletPossibilitiesSectionImage2.png"
                            loading="eager"
                            placeholder="none"
                            formats={["auto", "webp"]}
                            layout="constrained"
                            objectFit="contain"
                            alt=""
                        />
                    </WalletPossibilitiesSectionItemImage>
                </WalletPossibilitiesSectionItem>

                <WalletPossibilitiesSectionItem style={{'width': width && width > LAPTOP_MOBILE_POINTER ? '555px' : '100%', 'height': width && width > LAPTOP_MOBILE_POINTER ? '438px' : '100%', 'background': 'var(--background-second)'}}>
                    <WalletPossibilitiesSectionItemInfo style={{'width': '100%'}}>
                        <TypographyUI.H3 className="title">Платежи в Телеграм</TypographyUI.H3>
                        <TypographyUI.Body1Regular className="description">Автоматизируйте прием денег от клиентов через Телеграм-бот</TypographyUI.Body1Regular>
                    </WalletPossibilitiesSectionItemInfo>
                    <WalletPossibilitiesSectionItemImage style={{'position': width && width > LAPTOP_MOBILE_POINTER ? 'absolute' : 'relative', 'bottom': '20px', 'right': width && width > LAPTOP_MOBILE_POINTER ? 'calc(50% - 246px)' : '0', 'width': width && width > LAPTOP_MOBILE_POINTER ? '493px' : '100%'}}>
                        <StaticImage
                            src="../../../images/wallet/WalletPossibilitiesSectionImage3.png"
                            loading="eager"
                            placeholder="none"
                            formats={["auto", "webp"]}
                            layout="constrained"
                            objectFit="contain"
                            alt=""
                        />
                    </WalletPossibilitiesSectionItemImage>
                </WalletPossibilitiesSectionItem>


                <WalletPossibilitiesSectionItem style={{'width': width && width > LAPTOP_MOBILE_POINTER ? '729px' : '100%', 'height': width && width > LAPTOP_MOBILE_POINTER ? '438px' : '100%', 'background': 'var(--background-second)'}}>
                    <WalletPossibilitiesSectionItemInfo style={{'maxWidth': width && width > LAPTOP_MOBILE_POINTER ? '43%' : '100%'}}>
                        <TypographyUI.H3 className="title">Эквайринг</TypographyUI.H3>
                        <TypographyUI.Body1Regular className="description">Настройте оплату на вашем сайте по API или через готовое решение без открытия расчетного счета</TypographyUI.Body1Regular>

                        <AdaptiveLinkCt to="https://pro.selfwork.ru">
                            <WalletPossibilitiesSectionBtn>Подключить</WalletPossibilitiesSectionBtn>
                        </AdaptiveLinkCt>

                    </WalletPossibilitiesSectionItemInfo>
                    <WalletPossibilitiesSectionItemImage style={{'display': width && width > LAPTOP_MOBILE_POINTER ? 'block' : 'none', 'position': width && width > LAPTOP_MOBILE_POINTER ? 'absolute' : 'relative', 'bottom': '0', 'right': width && width > LAPTOP_MOBILE_POINTER ? '24px' : '0', 'width': width && width > LAPTOP_MOBILE_POINTER ? '362px' : '100%'}}>
                        <StaticImage
                            src="../../../images/wallet/WalletPossibilitiesSectionImage4.png"
                            loading="eager"
                            placeholder="none"
                            formats={["auto", "webp"]}
                            layout="constrained"
                            objectFit="contain"
                            alt=""
                        />
                    </WalletPossibilitiesSectionItemImage>
                </WalletPossibilitiesSectionItem>

                <WalletPossibilitiesSectionItem style={{'width': width && width > LAPTOP_MOBILE_POINTER ? '391px' : '100%', 'height': width && width > LAPTOP_MOBILE_POINTER ? '438px' : '100%', 'background': 'var(--background-main-grey)'}}>
                    <WalletPossibilitiesSectionItemInfo>
                        <TypographyUI.H3 className="title">Простой вывод средств</TypographyUI.H3>
                        <TypographyUI.Body1Regular className="description">Получайте деньги на счет Кошелька и выводите на карты любых банков или другие электронные кошельки</TypographyUI.Body1Regular>
                    </WalletPossibilitiesSectionItemInfo>
                    <WalletPossibilitiesSectionItemImage style={{'position': width && width > LAPTOP_MOBILE_POINTER ? 'absolute' : 'relative', 'bottom': width && width > LAPTOP_MOBILE_POINTER ? '50px' : '0', 'right': width && width > LAPTOP_MOBILE_POINTER ? 'calc(50% - 110px)' : '0', 'width': width && width > LAPTOP_MOBILE_POINTER ? '221px' : '100%'}}>
                        <StaticImage
                            src="../../../images/wallet/WalletPossibilitiesSectionImage5.png"
                            loading="eager"
                            placeholder="none"
                            formats={["auto", "webp"]}
                            layout="constrained"
                            objectFit="contain"
                            alt=""
                        />
                    </WalletPossibilitiesSectionItemImage>
                </WalletPossibilitiesSectionItem>

                <WalletPossibilitiesSectionItem style={{'width': '100%', 'height': width && width > LAPTOP_MOBILE_POINTER ? '438px' : '100%', 'background': 'var(--background-main-grey)'}}>
                    <WalletPossibilitiesSectionItemInfo style={{'maxWidth': width && width > LAPTOP_MOBILE_POINTER ? '50%' : '100%'}}>
                        <TypographyUI.H3 className="title">Никаких скрытых и дополнительных платежей: сервис удерживает 3,5% комиссии только за вывод средств</TypographyUI.H3>

                        <AdaptiveLinkCt to="https://pro.selfwork.ru">
                            <WalletPossibilitiesSectionBtn>Открыть Кошелек</WalletPossibilitiesSectionBtn>
                        </AdaptiveLinkCt>

                    </WalletPossibilitiesSectionItemInfo>
                    <WalletPossibilitiesSectionItemImage style={{'display': width && width > LAPTOP_MOBILE_POINTER ? 'block' : 'none', 'position': width && width > LAPTOP_MOBILE_POINTER ? 'absolute' : 'relative', 'bottom': width && width > LAPTOP_MOBILE_POINTER ? '15px' : '15px', 'right': width && width > LAPTOP_MOBILE_POINTER ? '-10px' : '0', 'width': width && width > LAPTOP_MOBILE_POINTER ? '600px' : '100%'}}>
                        <StaticImage
                            src="../../../images/wallet/WalletPossibilitiesSectionImage6.png"
                            loading="eager"
                            placeholder="none"
                            formats={["auto", "webp"]}
                            layout="constrained"
                            objectFit="contain"
                            alt=""
                        />
                    </WalletPossibilitiesSectionItemImage>
                </WalletPossibilitiesSectionItem>

            </WalletPossibilitiesSectionItems>

        </WalletPossibilitiesSectionContainer>
    )
};

const WalletPossibilitiesSectionContainer = styled.section`
    display: flex;
    margin-top: 160px;
    align-items: center;
    justify-content: space-between;
    position: relative;
    flex-direction: column;
    margin-bottom: 100px;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        margin: 80px -19px 20px;
    }
`;

const WalletPossibilitiesSectionTitleContainer = styled.div`
    width: 100%;
    margin-bottom: 60px;
    text-align: center;
    @media (max-width: 609px) {
        padding: 0 20px;
    }
`;

const WalletPossibilitiesSectionTitle = styled(TypographyUI.H2)`
`;


const WalletPossibilitiesSectionItems = styled.div`
    width: 100%;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
`;

const WalletPossibilitiesSectionItem = styled.div`
    padding-top: 40px;
    padding-left: 28px;
    padding-right: 28px;
    border-radius: 20px;
    overflow: hidden;
    position: relative;
    @media (max-width: 609px) {
        border-radius: 0px;
        padding-top: 28px;
        padding-bottom: 20px;
    }
`;

const WalletPossibilitiesSectionItemInfo = styled.div`
    & .title {
        margin-bottom: 16px;
    }
    & .description {
        color: var(--text-secondary);
    }
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        padding-bottom: 16px;
    }
`;

const WalletPossibilitiesSectionItemImage = styled.div`
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        max-width: 300px;
        margin: 0 auto;
        padding: 16px 0 0;
    }
    
`;

const WalletPossibilitiesSectionBtn = styled(ButtonUI)`
    width: 276px;
    margin-top: 40px;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        margin-top: 20px;
        width: 100%;
    }
`;
